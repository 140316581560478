import React from 'react';
import "../styles/Course.css"
import { Link } from 'react-router-dom';
import WeInSocialNetwork from "../elements/WeInSocialNetwork"
import { faRedoAlt, faBrain, faCheckCircle, faLightbulb, faEuroSign, faLaptop, faCalendarAlt, faVideo, faTasks, faComments, faBook, faLanguage, faAward, faInfinity, faBolt, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmphasiseEvolventa, Blue, EmphasiseMontserrat } from "./CustomElements";


const CoursePage = () => {
  const iconsColor = "#004aad";

  return (
    <div>
      <div className="main-container-course-1">
        <div className="header-container-course-1 blue">
          <h1><EmphasiseEvolventa><Blue>Авторський курс «Ігри пам'яті»</Blue></EmphasiseEvolventa></h1>
          <div className='header-container-paragraph'>
            <p><EmphasiseMontserrat>Старт:</EmphasiseMontserrat> 10.01.25</p>
            <p><EmphasiseMontserrat>Формат:</EmphasiseMontserrat> Онлайн, гнучкий графік</p>
            <p><EmphasiseMontserrat>Тривалість:</EmphasiseMontserrat> 3 дні</p>
            <p>
              <EmphasiseMontserrat>Ціна:</EmphasiseMontserrat> 770 грн
            </p>
        </div>
          <Link><button>Запис закрито</button></Link>
        </div>

        <div className="course-2-container-courselist">
          <h2><EmphasiseEvolventa><Blue>Що ви отримаєте:</Blue></EmphasiseEvolventa></h2>
          <div className='course-1-courselist course-2-courselist'>
          <div>
            <FontAwesomeIcon icon={faBolt} color={iconsColor} size='2x'/>
            <p>
              <EmphasiseMontserrat>20 “фішок мозку”</EmphasiseMontserrat> для запам’ятовування, які стануть корисними у навчанні, роботі та повсякденному житті.
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faBook} color={iconsColor} size='2x'/>
            <p>
              <EmphasiseMontserrat>12 технік</EmphasiseMontserrat> для довготривалого запам’ятовування слів, фраз і будь-якої інформації.
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faLanguage} color={iconsColor} size='2x'/>
            <p>
              <EmphasiseMontserrat>200 найуживаніших слів німецької мови,</EmphasiseMontserrat> які ви більше ніколи не забудете.
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faClipboardList} color={iconsColor} size='2x'/>
            <p>
              <EmphasiseMontserrat>Щоденні завдання:</EmphasiseMontserrat> до 10 хвилин, 63 дні завдань, які зроблять вивчення слів звичкою, такою ж простою як чистити зуби.
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faAward} color={iconsColor} size='2x'/>
            <p>
              <EmphasiseMontserrat>Результат на все життя:</EmphasiseMontserrat> це не просто про 3 дні навчання, а про навички, які залишаться з вами назавжди.
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faInfinity} color={iconsColor} size='2x'/>
            <p>
              <EmphasiseMontserrat>Безкоштовний повторний доступ</EmphasiseMontserrat> до курсу і кожен з новими завданнями для вдосконалення ваших навичок.
            </p>
          </div>
          </div>
        </div>
      </div>

      <WeInSocialNetwork telegram={true} />

      <div className="main-container-course-1 small-padding">
        <div className="course-1-container blue">
          <h1><EmphasiseEvolventa><Blue>Для кого цей курс:</Blue></EmphasiseEvolventa></h1>
          <div className="course-1-for-whom">
            <div>
              <FontAwesomeIcon icon={faBrain} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, кому <EmphasiseMontserrat>важко запам’ятовувати нові слова</EmphasiseMontserrat></h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faRedoAlt} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто хоче <EmphasiseMontserrat>знайти дієвий спосіб запам’ятовувати інформацію назавжди,</EmphasiseMontserrat> а не до наступного уроку.</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faLightbulb} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для всіх, хто хоче <EmphasiseMontserrat>покращити пам’ять</EmphasiseMontserrat> і навчитися працювати з інформацією ефективно.</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faCheckCircle} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для людей, які хочуть <EmphasiseMontserrat>отримати довготривалий результат.</EmphasiseMontserrat></h2>
            </div>
          </div>
          {/* <Link to={"https://secure.wayforpay.com/button/b6fc6ebf7c0f5"} target='_blank'><button>Записатись</button></Link> */}
          <Link><button>Запис закрито</button></Link>
        </div>

        <div className="course-1-container">
          <h2><EmphasiseEvolventa><Blue>Як проходить курс?</Blue></EmphasiseEvolventa></h2>
          <div className="course-1-program course-2-program">
            <div>
              <FontAwesomeIcon icon={faLaptop} size="2x" color={iconsColor}></FontAwesomeIcon>
              <h2><EmphasiseMontserrat>Онлайн у живому форматі</EmphasiseMontserrat></h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faCalendarAlt} size="2x" color={iconsColor}></FontAwesomeIcon>
              <p><EmphasiseMontserrat>Тривалість:</EmphasiseMontserrat> 3 дні інтенсиву</p>
            </div>
            <div>
              <FontAwesomeIcon icon={faVideo} size="2x" color={iconsColor}></FontAwesomeIcon>
              <p><EmphasiseMontserrat>Вебінари:</EmphasiseMontserrat> 3 zoom-зустрічі зі мною (будуть доступні записи лекцій)</p>
            </div>
            <div>
              <FontAwesomeIcon icon={faTasks} size="2x" color={iconsColor}></FontAwesomeIcon>
              <p><EmphasiseMontserrat>Щоденні завдання:</EmphasiseMontserrat> до <EmphasiseMontserrat>10 хвилин на день</EmphasiseMontserrat></p>
            </div>
            <div>
              <FontAwesomeIcon icon={faComments} size="2x" color={iconsColor}></FontAwesomeIcon>
              <p><EmphasiseMontserrat>Підтримка:</EmphasiseMontserrat> спільний чат для обговорення та обміну досвідом</p>
            </div>
            <div>
              <FontAwesomeIcon icon={faEuroSign} size="2x" color={iconsColor}>
              </FontAwesomeIcon><h2><EmphasiseMontserrat>Ціна курсу: 770 грн</EmphasiseMontserrat></h2>
            </div>
          </div>
        </div>
        <div className="header-container-course-1 blue header-container-course-2">
          <div className='header-container-paragraph'>
            <p>Набридло забувати слова через день-два? Записуйтесь на наш міні-курс і навчиться запам’ятовувати легко, ефективно і назавжди — всього за 10 хвилин на день.
            </p>
            <p>
            "Ігри пам'яті" не просто про те, як вивчати німецьку, а й про реальні інструменти для того, щоб запам’ятовувати назавжди, а не до наступного уроку.
            </p>
            <h1>
            <Blue>Готові почати? Приєднуйтесь і гра почалась!</Blue>
            </h1>
        </div>
          <Link><button>Запис закрито</button></Link>
        </div>
      </div>
    </div>  
  );
};

export default CoursePage;
