import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { Blue } from "./CustomElements";
import CoursePhoto3 from '../img/course-photo-3.png'
// import emailjs from 'emailjs-com'

const CourseA0CTA = (props) => {
    // const [popUpFormOpened, setPopUpFormOpened] = useState(false);
    // const [email, setEmail] = useState("");
    // const [phone, setPhone] = useState("");
    // const [emailError, setEmailError] = useState("");
    // const [phoneError, setPhoneError] = useState("");
    // const [emailSent, setEmailSent] = useState(false)
    // const [emailSending, setEmailSending] = useState(false)
    // const [emailSendingError, setEmailSendingError] = useState(false);
  
    // const toggleForm = () => {
    //   if (!popUpFormOpened) {
    //     document.documentElement.style.overflow = "hidden";
    //   }
    //   if (popUpFormOpened) {
    //     document.documentElement.style.overflow = "auto";
    //   }
    //   setPopUpFormOpened(!popUpFormOpened);
    // };
  
    // const validateEmail = (email) => {
    //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    //   return emailRegex.test(email);
    // };
  
    // const validatePhone = (phone) => {
    //   const phoneRegexUa = /^\+380\d{9}$/; // Формат +380XXXXXXXXX
    //   const phoneRegexAut = /^\+43\d{10}$/; // Формат +43 ХХХ ХХХХХХХ 
    //   const phoneRegexDe = /^\+49\d{10}$/; // Формат +49 ХХХ XХХХХХХХ
    //   if(phoneRegexUa.test(phone.replaceAll(' ', ''))){
    //     return true;
    //   }
    //   else if(phoneRegexAut.test(phone.replaceAll(' ', ''))){
    //     return true;
    //   }
    //   else if(phoneRegexDe.test(phone.replaceAll(' ', ''))){
    //     return true;
    //   }
    //   return false;
    // };
  
    // const handleSubmit = (e) => {
    //   e.preventDefault(); // Запобігає перезавантаженню сторінки
    
    //   let valid = true;
    
    //   if (!validateEmail(email)) {
    //     setEmailError("Некоректний email");
    //     valid = false;
    //   }
    
    //   if (!validatePhone(phone)) {
    //     setPhoneError("Неправильний номер телефону (формат: +380XXXXXXXXX)");
    //     valid = false;
    //   }
    
    //   if (valid) {
    //     sendEmail(e); // Тепер тут не потрібно передавати email і phone
    //     setEmail("");
    //     setPhone("");
    //   }
    // };
    
    // const sendEmail = (e) => {
    //   setEmailSending(true);
    //   e.preventDefault(); // Виправлена помилка
    
    //   emailjs
    //     .sendForm("service_vbufzd2", "template_kc39b1v", e.target, '0tO42fq8zKS3nl20D')
    //     .then((result) => {
    //       console.log("Email sent:", result.text);
    //       setEmailSending(false);
    //       setEmailSent(true);
    //       e.target.reset(); // Очистка форми після відправки
    //     })
    //     .catch((error) => {
    //       console.log("Email error:", error.text);
    //       setEmailSending(false);
    //       setEmailSent(false);
    //       setEmailSendingError(true);
    //     });
    // };
    
  const targetDate = new Date("2025-04-07T00:00:00");
  const targetDate2 = new Date("2025-04-14T00:00:00");

  const calculateTimeLeft = (targetDate) => {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
  const [timeLeft2, setTimeLeft2] = useState(calculateTimeLeft(targetDate2));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft2(calculateTimeLeft(targetDate2));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const isTimeUp = timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;
  const isTimeUp2 = timeLeft2.days === 0 && timeLeft2.hours === 0 && timeLeft2.minutes === 0 && timeLeft2.seconds === 0;

  return (
    <div>
        <div className={`course-1-cta-container ${props.class}`}>
            <div>
                <div>
                    <h2>
                        <Blue><strong>Авторський курс:</strong></Blue> <q>Німецька А2: на крок ближче</q> 
                    </h2>
                    <h2>
                        <Blue><strong>Коли старт:</strong></Blue> 18.04.25
                    </h2>
                    <h2>
                        <Blue><strong>Формат:</strong></Blue> Онлайн
                    </h2>
                    <h2>
                        <Blue><strong>Тривалість:</strong></Blue> 3 місяці
                    </h2>
                    {!isTimeUp &&           
                    <h2>
                        <Blue><strong>Ціна:</strong></Blue> Від 2450грн
                    </h2>}
                    {isTimeUp && !isTimeUp2 &&
                    <h2>
                        <Blue><strong>Ціна:</strong></Blue> Від 2820грн
                    </h2>}
                    {isTimeUp && isTimeUp2 &&
                    <h2>
                        <Blue><strong>Ціна:</strong></Blue> Від 3240грн
                    </h2>}
                </div>
                <img src={CoursePhoto3} alt="course-1-cta" className="course-1-cta-img"/>
            </div>
            <Link to={"/course-a2"}><button>Деталі курсу</button></Link>
            {/* {popUpFormOpened &&            
            <div className="pop-up-form-container">
                {!emailSending && !emailError && !emailSent &&  <div className="pop-up-form">
                    <button className="pop-up-form-close-button" onClick={toggleForm}>❌</button>
                    <h2>Хочеш знати коли почнуться продажі курсу?</h2> 
                    <h3>Заповни форму і ми повідомимо тебе!</h3>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor="input-name">Ваше Ім'я</label>
                        <input type="text" name="input-name" required placeholder="Джон" />

                        <label htmlFor="input-surname">Ваше Призвіще</label>
                        <input type="text" name="input-surname" required placeholder="Доу" />

                        {emailError && <p style={{ color: "red" }}>{emailError}</p>}{!emailError && <label htmlFor="input-email">Ваш E-mail</label>}
                        <input
                        type="email"
                        name="user_email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="example@gmail.com"
                        />

                        {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}{!phoneError &&<label htmlFor="input-phone-number"> Ваш Номер телефону </label>}
                        <input
                        type="text"
                        name="phone_number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="+380XXXXXXXXX"
                        required
                        />
                        <button type="submit">Відправити</button>
                    </form>
                </div>}
                {popUpFormOpened && emailSending && !emailError && !emailSent &&  <div className="message"><button className="pop-up-form-close-button" onClick={toggleForm}>❌</button><h1>Ваш емейл відправляється, будь-ласка очікуйте.</h1></div>}
                {popUpFormOpened && !emailSending && !emailError && emailSent &&  <div className="message"><button className="pop-up-form-close-button" onClick={toggleForm}>❌</button><h1>Дякуємо за ваш інтерес! Щойно почнуться продажі курсу, ми відправимо повідомлення на вашу email адресу з посиланням на сторінку курсу</h1></div>}
                {popUpFormOpened && !emailSending && emailError && !emailSent &&  <div className="message"><button className="pop-up-form-close-button" onClick={toggleForm}>❌</button><h1>На-жаль ваш емейл не було відправлено, будь-ласка спробуйте ще раз.</h1></div>}
            </div>} */}
        </div>
    </div>
  )
};

export default CourseA0CTA;
