import React, { useState, useEffect } from 'react';
import "../styles/Course.css"
import { Link } from 'react-router-dom';
import WeInSocialNetwork from "../elements/WeInSocialNetwork"
import { faComments, faRedoAlt, faClock, faSpellCheck, faCommentDots, faHeadset, faGlobe, faArrowUp, faLayerGroup, faBrain, faChalkboardTeacher, faUsers, faUserGraduate, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmphasiseEvolventa, Blue, EmphasiseMontserrat } from "./CustomElements";
import CourseA0CTA from './CourseA0CTACreative';
import { Helmet } from "react-helmet-async";


const CoursePage = () => {
  const targetDate = new Date("2025-04-07T00:00:00");
  const targetDate2 = new Date("2025-04-14T00:00:00");

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  const calculateTimeLeft = (targetDate) => {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
  const [timeLeft2, setTimeLeft2] = useState(calculateTimeLeft(targetDate2));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft2(calculateTimeLeft(targetDate2));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const isTimeUp = timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;
  const isTimeUp2 = timeLeft2.days === 0 && timeLeft2.hours === 0 && timeLeft2.minutes === 0 && timeLeft2.seconds === 0;
  const iconsColor = "#004aad";

  return (
    <div>
      <Helmet>
        <title>Авторський курс «Німецька А2. На крок ближче»</title>
        <meta name="description" content="Запишіться на авторський курс «Німецька А2. На крок ближче» та вдоскональте свої навички! Ефективні методики, досвідчені викладачі, онлайн-доступ." />
      </Helmet>
      <div className="main-container-course-1">
        <div className="header-container-course-1 blue">
          <h1><EmphasiseEvolventa><Blue>Авторський курс «Німецька А2. На крок ближче»</Blue></EmphasiseEvolventa></h1>
          <div className='header-container-paragraph'>
            <p><EmphasiseMontserrat>Старт:</EmphasiseMontserrat> 18.04.25</p>
            <p><EmphasiseMontserrat>Формат:</EmphasiseMontserrat> Онлайн, гнучкий графік</p>
            <p><EmphasiseMontserrat>Тривалість:</EmphasiseMontserrat> 3 місяці</p>
            {!isTimeUp && <p>
              <EmphasiseMontserrat>Ціна:</EmphasiseMontserrat> Від 2450 грн
            </p>} 
            {isTimeUp && !isTimeUp2 && 
            <p>
              <EmphasiseMontserrat>Ціна:</EmphasiseMontserrat> Від 2820 грн
            </p>}
            {isTimeUp && isTimeUp2 && 
            <p>
              <EmphasiseMontserrat>Ціна:</EmphasiseMontserrat> Від 3240 грн до 18.04
            </p>}
            {!isTimeUp && <p>
              З 07.04 від 2820 грн
            </p>}
            {isTimeUp && !isTimeUp2 && 
            <p>
              З 14.04 від 3240 грн
            </p>}
          </div>
          <Link><button onClick={() => scrollToSection('course-tariff-plans')}>Записатись на онлайн курс</button></Link>
        </div>

        <div className="course-1-container-courselist">
          <h2><EmphasiseEvolventa><Blue>Що ви отримаєте:</Blue></EmphasiseEvolventa></h2>
          <div className='course-1-courselist'>
            <p> <EmphasiseMontserrat>23 відеоуроки</EmphasiseMontserrat> з простим та чітким поясненням граматики</p>
            <p><EmphasiseMontserrat>24 аудіювання</EmphasiseMontserrat> для покращення розуміння на слух</p>
            <p><EmphasiseMontserrat>21 розмовне завдання</EmphasiseMontserrat> — починаємо говорити як німці</p>
            <p><EmphasiseMontserrat>150+ сторінок</EmphasiseMontserrat> домашніх завдань у PDF — практики забагато не буває</p>
            <p><EmphasiseMontserrat>12 живих занять</EmphasiseMontserrat> у Zoom із викладачем</p>
            <p><EmphasiseMontserrat>2 лекції</EmphasiseMontserrat> з психологом для подолання мовного бар'єра.</p>
            <p><EmphasiseMontserrat>Розмовні клуби</EmphasiseMontserrat> з першої лекції.</p>
          </div>
        </div>
      </div>

      <WeInSocialNetwork telegram={true} />

      <div className="main-container-course-1 small-padding">
        <div className="course-1-container blue">
          <h1><EmphasiseEvolventa><Blue>Для кого цей курс:</Blue></EmphasiseEvolventa></h1>
          <div className="course-1-for-whom">
            <div>
              <FontAwesomeIcon icon={faArrowUp} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто вже пройшов A1 і хоче рухатися далі. Цей курс — логічний крок до більш впевненого володіння мовою.</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faLayerGroup} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто хоче продовжити систематизувати знання. Продовжуємо розкладати все по поличках та заповнювати прогалини.</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faComments} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто хоче ще більше і ще впевненіше говорити. Долаємо мовні бар'єри завдяки продуманим розмовним завданням та клубам.</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faBrain} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто хоче довести свою німецьку до автоматизму.</h2>
            </div>
          </div>
          <Link><button>Записатись на курс</button></Link>
        </div>

        <CourseA0CTA scrollTo={() => scrollToSection('course-tariff-plans')} buttonText={'Записатись на курс'} class={'wholescreen columns'} creativeText={'Готові зробити наступний крок до впевненості? Записуйся на курс, щоб підняти свою німецьку на новий рівень!'}></CourseA0CTA>

        <div className="course-tariff-plans" id='course-tariff-plans'>
          <div className='plan'>
            <h2> <EmphasiseEvolventa>Самостійний</EmphasiseEvolventa><FontAwesomeIcon icon={faUserGraduate} size="2x" color="#black" /></h2>
            <p>Доступ до матеріалів (відео, аудіо, текст)</p>
            <p>Участь у 12 Zoom-уроках</p>
            <p>2 лекції з психологом</p>
            <p>Безкоштовний перехід у наступний потік</p>
            <p className='crossed'>Перевірка розмовних завдань</p>
            <p className='crossed'>Доступ до розмовних клубів</p>
            <p className='crossed'>Індивідуальні заняття(6 занять по 50 хв)</p>
            <p className='crossed'>Лекція з вимови</p>
            <p>Ціна: 2450грн</p>
            <Link to={'https://secure.wayforpay.com/button/bc62950208cbd'}><button>Обрати цей тариф</button></Link>
          </div>
          <div className='plan'>
            <h2><EmphasiseEvolventa>З викладачем у групі</EmphasiseEvolventa><FontAwesomeIcon icon={faChalkboardTeacher} size="2x" color={iconsColor} /></h2>
            <p>Доступ до матеріалів (відео, аудіо, текст)</p>
            <p>Участь у 12 Zoom-уроках</p>
            <p>2 лекції з психологом</p>
            <p>Безкоштовний перехід у наступний потік</p>
            <p>Перевірка розмовних завдань</p>
            <p>Доступ до розмовних клубів (лише 10 зустрічей)</p>
            <p className='crossed'>Індивідуальні заняття (6 занять по 50 хв)</p>
            <p className='crossed'>Лекція з вимови</p>
            <p>Ціна: 3300грн</p>
            <Link to={'https://secure.wayforpay.com/button/bee347518456b'}><button>Обрати цей тариф</button></Link>
          </div>
            <div className='plan'>
            <h2><EmphasiseEvolventa><Blue>Персональний</Blue></EmphasiseEvolventa><FontAwesomeIcon icon={faRocket} size="2x" color="#d73502" /></h2>
            <p>Доступ до матеріалів (відео, аудіо, текст)</p>
            <p>Участь у 12 Zoom-уроках</p>
            <p>2 лекції з психологом</p>
            <p>Безкоштовний перехід у наступний потік</p>
            <p>Перевірка розмовних завдань (детальний розбір)</p>
            <p>Доступ до розмовних клубів (10 зустрічей) + екслюзивні клуби після курсу</p>
            <p>Індивідуальні заняття (6 занять по 50 хв)</p>
            <p>Лекція з вимови</p>
            <p>Ціна: 15600грн</p>
            <Link to={'https://secure.wayforpay.com/button/b75072ebc4935'}><button>Обрати цей тариф</button></Link>
          </div>
        </div>

        <div className="course-1-container">
          <h2><EmphasiseEvolventa><Blue>Програма курсу:</Blue></EmphasiseEvolventa></h2>
          <div className="course-1-program">
            <h1>Курс складається з 12 лекцій та завдань до них, розроблених та написаних мною, які охоплюють граматику, аудіювання та розмовні навички:</h1>
            <h2>Граматика та структура речень:</h2>
            <p>Понад 10 сполучників</p>
            <p>Інфінітивні конструкції</p>
            <p>Прийменники місця</p>
            <p>Приставки + приставки в складнопідрядних реченнях</p>
            <p>Прислівники місця</p>
            <p>Модальні дієслова</p>
            <p>Неозначено-особові та негативні займенники</p>
            <p>Відмінювання прикметників (повне)</p>
            <p>Скорочення прийменників</p>
            <p>Passiv Präsens</p>
            <p>Відносні займенники</p>
            <p>Konjunktiv 2</p>
            <p>Ступені порівняння прикметників</p>
            <p>Прийменники з різними відмінками</p>
            <p>Прийменники місцезнаходження</p>
            <p>Суфікси і логіка утворення складених слів</p>
            <p>Präteritum</p>
            <p>Wo- Da-</p>
            <p>Futur 1</p>
            <h2>Вимова та аудіювання:</h2>
            <p>Аудіювання та вправи на сприйняття</p>
            <p>Вимова + вправи</p>
            <h2>Розмовна практика:</h2>
            <p>Розмовні завдання після кожного уроку</p>
            <p>Відпрацювання лексики для реальних життєвих ситуацій</p>
          </div>
        </div>

        <div className="course-1-container-courselist blue">
          <h2><EmphasiseEvolventa><Blue>Що буде після курсу? Фокус на розмові для реального життя</Blue></EmphasiseEvolventa></h2>
          <div className='course-1-courselist'>
            <h2><EmphasiseEvolventa>Словниковий запас:</EmphasiseEvolventa></h2>
            <p> <EmphasiseMontserrat>1700 слів</EmphasiseMontserrat> для розуміння</p>
            <p><EmphasiseMontserrat>1300 слів </EmphasiseMontserrat> для активного використання</p>
            <h2><EmphasiseEvolventa>Розмови, балачки, спілкування та комунікація:</EmphasiseEvolventa></h2>
            <p>Впевнене спілкування у <EmphasiseMontserrat>трьох часах:</EmphasiseMontserrat> минулому, теперішньому та майбутньому</p>
            <p><EmphasiseMontserrat>Зв’язне мовлення замість окремих фраз</EmphasiseMontserrat>  — вивчаємо сполучники, вводні конструкції, слова-зв’язки</p>
            <p><EmphasiseMontserrat>Використання модальних дієслів </EmphasiseMontserrat> у розповіді як ото німці роблять</p>
            <p><EmphasiseMontserrat>Вміння писати листи </EmphasiseMontserrat>лікарю, колегам, керівництву</p>
            <h2><EmphasiseEvolventa>Тематики, які охопимо:</EmphasiseEvolventa></h2>
            <p><EmphasiseMontserrat>Робота: </EmphasiseMontserrat>пошук, лікарняні, відпустка, спілкування з керівництвом і колегам, професія</p>
            <p><EmphasiseMontserrat>Подорожі: </EmphasiseMontserrat>планування, покупки, розповіді про досвід, орієнтування в місті</p>
            <p><EmphasiseMontserrat>Оренда житла та побут:</EmphasiseMontserrat>  квартирні питання, домашнє господарство</p>
            <p><EmphasiseMontserrat>Здоров’я: </EmphasiseMontserrat>лікар, аптека, краса</p>
            <p><EmphasiseMontserrat>Спорт: </EmphasiseMontserrat>запис в спортзал і вправи</p>
            <p><EmphasiseMontserrat>Шопінг: </EmphasiseMontserrat>онлайн і оффлайн покупки</p>
            <p><EmphasiseMontserrat>Повсякденні справи: </EmphasiseMontserrat>кухня і рецепти, погода, пошта, автомобіль (СТО)</p>
            <p><EmphasiseMontserrat>Культура: </EmphasiseMontserrat>свята, театр, кіно, книги, сім’я, весілля</p>
            <p><EmphasiseMontserrat>Small talk, e-mail переписка та повсякденні розмови.</EmphasiseMontserrat></p>
            <h2><EmphasiseEvolventa>+ Бонуси:</EmphasiseEvolventa></h2>
            <p><EmphasiseMontserrat>Розуміння культурного коду німців: </EmphasiseMontserrat>дізнаєтесь, що дарувати, коли приходите в гості, як поводитися в гостях, чому порядок - святе, що не так з білими розами й чому німці їх не дарують, тощо.</p>
            <p><EmphasiseMontserrat>Знаходимо спільну мову з німцями: </EmphasiseMontserrat>глибше розуміння німецького менталітету та легша інтеграція в німецькомовне середовище.</p>
          </div>
        </div>

        <CourseA0CTA scrollTo={() => scrollToSection('course-tariff-plans')} buttonText={'Записатись на курс'} class={'wholescreen columns'} creativeText={'Готові перейти від «Ich bin...» до впевненої розмови про все на світі? Цей курс — саме те, що треба'}/>

        <div className="course-1-container">
          <h2 className='text-center-align'><EmphasiseEvolventa><Blue>Переваги нашого авторського курсу з вивчення німецької A1:</Blue></EmphasiseEvolventa></h2>
          <div className="course-1-benefits">
            <div>
                <FontAwesomeIcon icon={faChalkboardTeacher} size="3x" color={iconsColor} />
                <div className="container-course-1-benefit">
                    <h2><EmphasiseEvolventa>Живі онлайн уроки:</EmphasiseEvolventa></h2>
                    <p>Щотижневі Zoom-сесії для питань, пояснень і практики. Не тільки я, Тайс обіцяє теж бути присутнім 😉</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faUsers} size="3x" color={iconsColor} />
                <div className="container-course-1-benefit">
                    <h2><EmphasiseEvolventa>Доступ до спільноти та підтримка нон-стоп:</EmphasiseEvolventa></h2>
                    <p>Спільний чат для спілкування та підтримки від мене та інших учасників курсу.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faClock} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Доступ до матеріалів 24/7:</EmphasiseEvolventa></h2> 
                  <p>Всі матеріали будуть доступні з першого дня курсу. Навчаєтесь у зручний час та у власному темпі.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faHeadset} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Постійний зворотний зв'язок:</EmphasiseEvolventa></h2>
                  <p>Заповнююте Google Forms, а відповіді будуть на щотижневих Zoom-сесіях, записи яких будуть доступні.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faCommentDots} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Практичні завдання:</EmphasiseEvolventa></h2>
                  <p>Без розмов немає прогресу, тож ви виконуйте розмовні завдання в Telegram, а я особисто перевірю і дам фідбек.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faSpellCheck} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Граматика простими словами:</EmphasiseEvolventa></h2> 
                  <p>В основі курсу — Duden Gramatik (офіційне джерело німецької), але подано у стилі Анастасії Севастьянової — простими словами про складне.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faGlobe} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Життєві та актуальні теми:</EmphasiseEvolventa></h2>
                  <p>Вивчаємо те, що реально використовуєте в житті: подорожі, робота, побут.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faRedoAlt} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Безкоштовне повторне проходження:</EmphasiseEvolventa></h2>
                  <p>Ви зможете пройти курс повторно в наступному потоці свого тарифу як повноцінний учасник — безкоштовно!</p>
                </div>
            </div>
          </div>
          <Link><button className='blue' onClick={() => scrollToSection('course-tariff-plans')}>Записатись на онлайн курс</button></Link>
        </div>
      </div>
    </div>  
  );
};

export default CoursePage;
