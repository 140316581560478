import React from "react"
import '../styles/AboutMe.css'
import { Blue, EmphasiseMontserrat, EmphasiseEvolventa } from "./CustomElements";
import Tyson from "../img/tyson.png"
import { Link } from "react-router-dom";

const AboutMe = (props) => {
  return (
    <div>
        <div className="about-me-container">
          <div class="about">
            <div class="about-heading">
              <h1><Blue>Хей-хай, я — <EmphasiseMontserrat>Анастасія Севастьянова.</EmphasiseMontserrat></Blue></h1>
              <p>
                  Я поліглот і вивчила за своє життя <EmphasiseMontserrat>4 іноземні мови.</EmphasiseMontserrat> Допоможу і вам. Понад <EmphasiseMontserrat>10 років</EmphasiseMontserrat> живу в Берліні (4 з них з котиком Тайсоном <img src={Tyson} alt="Tyson" /> ), маю 9 років досвіду викладання німецької мови та <EmphasiseMontserrat>4 вищі освіти</EmphasiseMontserrat> (2 з них філологічні).
              </p>
            </div>

            <div class="about-education">
                <h1><Blue><EmphasiseEvolventa>Освіта:</EmphasiseEvolventa></Blue></h1>
                <div>
                    <p>Університет Потсдам, Німеччина (літератор/письменник);</p>
                    <p>Університет ім. В. Н. Каразіна, Україна (викладач німецької, англійської, французької мов).</p>
                </div>
            </div>

            <div class="about-approach">
                <h1><Blue><EmphasiseEvolventa>Мій підхід:</EmphasiseEvolventa></Blue></h1>
                <p>
                    Вивчаючи мови за стандартними методиками, я зрозуміла, що це не завжди ефективно, а ще зазвичай нудно та нецікаво.
                    Тому я розробила <EmphasiseMontserrat>власний</EmphasiseMontserrat> підхід, орієнтований на <EmphasiseMontserrat>результат</EmphasiseMontserrat>. Теми курсу актуальні та життєві — знання, які ви здобудете,
                    <EmphasiseMontserrat> точно</EmphasiseMontserrat> знадобляться у повсякденному житті.
                </p>
            </div>

            <div class="about-motivation">
                <h1><Blue><EmphasiseEvolventa>Чому я:</EmphasiseEvolventa></Blue></h1>
                <p>
                    Моє завдання — не просто викласти матеріал, а навчити вас розуміти та впевнено говорити німецькою.
                    Я переконана, що <EmphasiseMontserrat>кожен</EmphasiseMontserrat> може вивчити та говорити німецькою, якщо викладання чітке, структуроване і цікаве.
                </p>
                <p>
                    Серед 400+ моїх студентів були ті, хто думав, що ніколи не зможе заговорити німецькою, але завдяки моєму підходу
                    і підтримці вони <EmphasiseMontserrat>подолали всі бар’єри</EmphasiseMontserrat>. А 97,5%, які вивчали німецьку для підготовки до іспитів, <EmphasiseMontserrat>успішно склали</EmphasiseMontserrat> Goethe, ÖSD, TestDaF. Ви також зможете! Записуйтесь на курс і врешті решт вивчіть німецьку!
                </p>
                <Link to={"/course-1"}><button>Запис закрито</button></Link>
            </div>
          </div>
        </div>      
    </div>
  )
};

export default AboutMe;
