import React from "react"
import '../styles/courses.css'
import CoursePhoto1 from '../img/course-photo-1.png'
import CoursePhoto2 from '../img/course-photo-2.png'
import CoursePhoto3 from '../img/course-photo-3.png'
import { Link } from "react-router-dom";

const name = (props) => {
  return (
    <div>
        <div className="courses">
          <div className="courses-header">
            <h2>Наші курси</h2>
            <p>Пориньте у світ знань разом з нами! Виберіть один із наших курсів і розкрийте свій потенціал, здобуваючи нові навички та знання. Ми пропонуємо якісне навчання з індивідуальним підходом до кожного студента.</p>
          </div>
          <div className="courses-grid">
          <div className="course">
            <div>
              <img src={CoursePhoto1} alt="course-photo-1" />
              <h2>Німецька А1 прям з 0</h2>
            </div>
            <Link to={'/course-1'}><button>Придбати</button></Link>
          </div>
          <div className="course">
            <div>
              <img src={CoursePhoto2} alt="course-photo-2" />
              <h2>Ігри пам'яті</h2>
            </div>
            <Link to={'/course-2'}><button>Придбати</button></Link>
          </div>
          <div className="course">
            <div>
              <img src={CoursePhoto3} alt="course-photo-2" />
              <h2>Німецька А2: мова стає навичкою</h2>
            </div>
            <Link to={'/course-a2'}><button>Придбати</button></Link>
          </div>
        </div>
        </div>
    </div>
  )
};

export default name;
